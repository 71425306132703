<template>
	<div class="footer bg-bw bg-waveso-l-2">
		<div class="container py-7">
			<div class="row g-3">
				<div class="col-lg-5">
					<img class="mb-4" src="../../assets/logo-kemdikbud.png" width="80" alt="">
					<p class="r-l-1">Badan Standar, Kurikulum, dan Asesmen Pendidikan. Kementerian Pendidikan Dasar dan Menengah.</p>
				</div>
				<div class="col-lg-2">
					<h5 class="mt-3">Peta Situs</h5>
					<ul class="mt-5 list-situs-footer">
						<li class="content-link h-b"><a href="#" v-on:click="go('/')">Beranda</a></li>
						<li class="content-link h-b"><a href="#" v-on:click="go('/tentang')">Tentang</a></li>
						<li class="content-link h-b"><a href="#" v-on:click="go('/program')">Program</a></li>
						<li class="content-link h-b"><a href="#" v-on:click="go('/kontak')">Kontak</a></li>
					</ul>
				</div>
				<div class="col-lg-5">
					<div class="row">
						<div class="col">
							<h5 class="mt-3 align-middle">Hubungi Kami</h5>
						</div>
						<div class="col list-icon-sosial">
							<div class="row">
								<div class="col d-flex justify-content-end p-0">
									<a href="https://www.facebook.com/SIBIpusbuk/" target="_blank">
										<img src="../../assets/icon-fb.png" width="36" alt="">
									</a>
								</div>
								<div class="col d-flex justify-content-center p-0">
									<a href="https://www.instagram.com/sibi_kemdikbud/" target="_blank">
										<img src="../../assets/icon-ig.png" width="36" alt="">
									</a>
								</div>
								<div class="col d-flex justify-content-start p-0">
									<a href="https://www.youtube.com/channel/UCLm7X0R-hLHArgF88Z6OPQw" target="_blank">
										<img src="../../assets/icon-yt.png" width="36" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>
					<ul class="mt-4 pt-2 list-situs-footer">
						<li>
							<span><img src="../../assets/icon-location.png" width="15" alt=""></span>
							<p class="ms-4">Jalan RS. Fatmawati Gd D Komplek Kemendikdasmen Cipete, Jakarta 12410</p>
						</li>
						<li>
							<span><img src="../../assets/icon-phone.png" width="15" alt=""></span>
							<p class="ms-4">021-3804248</p>
						</li>
						<li>
							<span><img src="../../assets/icon-email.png" width="15" alt=""></span>
							<p class="ms-4">buku@kemdikbud.go.id</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="bg-bb p-3 text-center">
			<p class="m-0 f-white">© Copyrights 2022 Sistem Informasi Perbukuan Indonesia. All rights reserved.</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	methods: {
		go(link){
			this.$router.push(link);
		}
	}
}
</script>